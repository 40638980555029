import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Header } from "../../components";
import { useToolsContext } from "../../hooks/useToolsContext";
import axios from "axios";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { UserContext } from "../../contexts/UserContext";

const CodingHelper = (props) => {
  const { language } = props;
  const { dispatch } = useToolsContext();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [code, setCode] = React.useState("");

  const { user, setUser } = useContext(UserContext);

  const usertoken = JSON.parse(localStorage.getItem("user"));
  const { token } = usertoken;

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setIsLoading(true); 
    setCode([]); 

  
    const query = { question, codeLanguage:language };
  
   try {
    
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/tools/codinghelper`,
        query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false); // set loading to false
      const text = response.data;

      const newCredit = parseFloat(user.credit + 1); 
      setUser({ ...user, credit: newCredit }); 
      
      // Simulate typing progress
      setTimeout(() => {
          let currentLength = '';
          for (let i = 0; i < text.length; i++) {
            setTimeout(() => {
              //add a single letter from the text previously fetched from the server
              setCode(c => c + text[i]);
              }, i*75);
          }
      },500);
   
      setQuestion(''); // clear input field
      setError(null);
      setEmptyFields([]);
      
    }
    catch (error) {
        setIsLoading(false); // set loading to false
        if (error.response) {
          if (error.response.status === 402) {
            setError("Not enough credit.");
            navigate("/settings/billing");

          } else {
            setError(error.response.data.error);
            setEmptyFields(error.response.data.emptyFields);
          }
       } else {
         setError("Something went wrong. Please try again later.");
       }
    }
  };
  

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Coding Section" title={language || "Code"} />

      <div className="mt-10">
        <div id="assignment-helper-input">
          <form onSubmit={handleSubmit}>
            <textarea
              type="text"
              required={true}
              placeholder="Enter your question"
              value={question}
              rows={3}
              onChange={(e) => setQuestion(e.target.value)}
              className="w-full mt-4 p-2 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-indigo-500"
            />
            <button
              type="submit"
              className="w-full px-4 py-2 mt-4 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-700"
            >
              Ask Question
            </button>
          </form>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center mt-10">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
        <div className="rounded border-gray border border-spacing-1 p-5 mt-10 ">
          <CodeEditor
            value={code}
            language={`${language}`}
            data-color-mode="dark"
            placeholder="..."
            onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            style={{
              fontSize: 12,
              //   backgroundColor: "#f5f5f5",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default CodingHelper;
