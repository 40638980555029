import React from "react";
import { Link } from "react-router-dom";
import { BsBoxSeam } from "react-icons/bs";

const Cardv2 = ({ name, description, icon, destination }) => {
  return (
    <Link to={destination}>
      {/* create a card that contains a large icon and a h3 text and p what have a drop shadow on hover */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-5 hover:shadow-2xl transition duration-300 ease-in-out">
        <div className="flex items-center gap-3">
          <div className="bg-gray-100 dark:bg-gray-700 rounded-full p-3">
            <img src={icon} className="h-12 w-12" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {name}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Cardv2;
