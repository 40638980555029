import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../components";
import { ToolsContextProvider } from "../contexts/ToolsContext";
import { useToolsContext } from "../hooks/useToolsContext";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";

const Assignment = () => {
  const { dispatch } = useToolsContext();
  const [question, setQuestion] = useState("");
  const [title, setTitle] = useState("");
  const [answeredQuestion, setAsnweredQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const userToken = JSON.parse(localStorage.getItem("user"));
  const { token } = userToken;

const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setEmptyFields([]);
    setTitle("");
    setAsnweredQuestion("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/tools/questionhelper`,
        { question },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({ type: "ESSAY_WRITING", payload: response.data });
      setTitle(question);
      setQuestion("");

      const text = response.data;

      const newCredit = parseFloat(user.credit + 1); 
      setUser({ ...user, credit: newCredit }); 
    

      // Simulate typing progress
      setTimeout(() => {
        let currentLength = "";
        for (let i = 0; i < text.length; i++) {
          setTimeout(() => {
            //add a single letter from the text previously fetched from the server
            setAsnweredQuestion((c) => c + text[i]);
          }, i * 25);
        }
      }, 500);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 402) {
          setError("Not enough credit.");
          navigate("/settings/billing");
        } else {
          setError(err.response.data.error);
          setEmptyFields(err.response.data.emptyFields);
        }
     } else {
       setError("Something went wrong. Please try again later.");
     }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Tools" title="Assignment Helper" />

      <div className="mt-10">
        <div id="assignment-helper-input">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              required={true}
              placeholder="Enter your question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            />
            <button
              type="submit"
              className="w-full px-4 py-2 mt-4 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-700"
            >
              Ask Question
            </button>
          </form>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center mt-10">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div
            id="answer-output"
            className="rounded border-gray border border-spacing-1 p-5 mt-10"
          >
            <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
            <p className="text-gray-500">
              <span>{answeredQuestion}</span>
            </p>
          </div>
        )}
      </div>

    </div>
  );
};

export default Assignment;
