import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RevisionListCard from "./RevisionListCard";

const RevisionsList = () => {
  const [revisions, setRevisions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("user"));
  const { token } = userToken;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/tools/revisions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setRevisions(response.data);
        setIsLoading(false);

      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

 const handleOpen = (revisionName, revisionData) => {
   navigate("/revision/answer", {
     state: {
       revisionName,
       revisionData,
     },
   });
 };
 

const handleDelete = async (revisionId) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this revision?");

  if (confirmDelete) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/tools/revisions/${revisionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setRevisions(revisions.filter((revision) => revision._id !== revisionId));

      console.log(response.data.message);
    } catch (error) {
      console.error(error);
    }
  }
};


  return (
    <div className="flex flex-col ">
      <div className=" flex flex-row justify-center mt-4">
        <h2 className=" align-middle text-lg">My Revisions</h2>
      </div>

      {isLoading ? (
        <p>Loading revisions...</p>
      ) : revisions.length === 0 ? (
        <p>No revisions found.</p>
      ) : (
        revisions.map((revision) => (
         <RevisionListCard
           key={revision._id}
           onDelete={() => handleDelete(revision._id)}
           onOpen={() => handleOpen(revision.revisionName, revision.QNA)}
           title={revision.revisionName}
           date={new Date(revision.createdAt).toLocaleString("en-US", {
             weekday: "short",
             month: "short",
             day: "numeric",
             year: "numeric",
             hour: "numeric",
             minute: "numeric",
             hour12: true,
           })}
         />
         
          
        ))
      )}
    </div>
  );
};

export default RevisionsList;
