import React, { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const RevisionCard = ({ index, item, dismissCard }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    });
  };

  return (
    <div className="flex flex-col mt-4 bg-gray-100 p-5 rounded-lg">
      <div className="flex flex-row">
        <h3 className="text-lg basis-11/12">{item.question.split("Q: ")}</h3>
        <div className="flex flex-row justify-end basis-1/12">
          <MdContentCopy
            className=" text-lg mx-2"
            onClick={() =>
              handleCopyClick(
                "Question: " + item.question + "\nAnswer: " + item.answer
              )
            }
            style={{ color: copied ? "grey" : "inherit", cursor: "pointer" }}
          />
          <RxCross2
            className=" text-lg mx-2 cursor-pointer"
            onClick={dismissCard}
          />
        </div>
      </div>
      <div className="flex-col mt-2">
        <p>
          <span>{item.answer}</span>
        </p>
      </div>
    </div>
  );
};

export default RevisionCard;
