import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { ToolsContextProvider } from "./contexts/ToolsContext";
import { AuthContextProvider } from "./contexts/AuthContext";
import { UserContextProvider } from "./contexts/UserContext";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ContextProvider>
        <UserContextProvider>
          <ToolsContextProvider>
            <App />
          </ToolsContextProvider>
        </UserContextProvider>
      </ContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
