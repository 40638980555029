import React, { useState, useEffect, useContext } from "react";
import { Header } from "../../components";
import { PlanCard } from "../../components";
import "./style.css";

import { UserContext } from "../../contexts/UserContext";

const BillingSection = () => {
  const { user, setUser } = useContext(UserContext);

  // fetch user profile and update usercontext
  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/miscs/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      setUser(data);
    };

    fetchUser();
  }, []);

  const [isMonthly, setIsMonthly] = useState(true);

  const userToken = JSON.parse(localStorage.getItem("user"));
  const { token } = userToken;

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Settings" title="Billing Section" />
      <div className="flex flex-col gap-5">
        <p className=" m-3 mt-4 uppercase">Current Plan</p>
        <div className="flex flex-col gap-5 ml-4">
          <p className="text-gray-500 dark:text-gray-500">
            <span>Current Plan : {user.plan}</span>
          </p>
          <p className="text-gray-500 dark:text-gray-500">
            <span>Available Words: {user.credit} Words</span>
          </p> <p className="text-gray-500 dark:text-gray-500">
          <span>Avialable Revisions: {user.revisions} Revisions</span>
          </p>

            <a 
              className="bg-purple-800 hover:bg-purple-900 text-white font-bold py-2 px-4 w-1/4 rounded-xl"
              href="https://billing.stripe.com/p/login/cN27uI2nBc3732o000"
            >
              {" "}
              Manage Subscription{" "}
            </a>
          
        </div>
      </div>
      <div className="flex flex-col gap-5 mt-5">
        <p className=" m-3 mt-4 uppercase">Upgrade your plan</p>

        <div className="grid  gap-5 ml-4">
          <stripe-pricing-table
            pricing-table-id={`${process.env.REACT_APP_STRIPE_PRICE_TABLE_ID}`}
            publishable-key={`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`}
            customer-email={`${user.email}`}
          ></stripe-pricing-table>
        </div>
      </div>
    </div>
  );
};

export default BillingSection;
