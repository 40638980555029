import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";

const ChapterRevisionsInput = () => {
    const [text, setText] = useState("");

    const navigate = useNavigate();

    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    const handlePaste = (event) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData("text/plain");
      setText(text + pastedText);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      navigate("/revision/answer", {
        state: {
          chaptertopic: text,
        },
      });
  
      setText("");
    };
  
    const wordCount = text.trim().split(/\s+/).length;
    const remainingWords = 1501 - wordCount;
    const excessWords = remainingWords < 0 ? Math.abs(remainingWords) : 0;

  return (
    <div className="flex flex-col">
            <form onSubmit={handleSubmit}>
              <textarea
                value={text}
                onChange={handleTextChange}
                onPaste={handlePaste}
                placeholder="Please enter your lesson here..."
                required={true}
                rows={20}
                className="w-full mt-4 p-2 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-purple-500"
              />
              <div
                className={`flex justify-between items-center mt-2 text-sm ${
                  remainingWords < 0 ? "text-red-500" : ""
                }`}
              >
                <h2>
                  {remainingWords > 0 ? remainingWords : 0} words remaining
                  {excessWords > 0 ? `, exceeded by ${excessWords} words` : ""}
                </h2>
              
              </div>
              <button
                className={`bg-indigo-700 text-white rounded-md px-4 py-2 mt-4 ${
                  remainingWords < 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={remainingWords < 0}
              >
                Get Questions and Answers
              </button>
            </form>
          </div>
  )
}

export default ChapterRevisionsInput