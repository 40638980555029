import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { Card, Cardv2 } from "../components";
import { BsFileEarmarkText } from "react-icons/bs";
import writerIcon from "../assets/icons/writer.svg";
import helperIcon from "../assets/icons/helper.svg";
import revisionsIcon from "../assets/icons/revisions.svg";
import codingIcon from "../assets/icons/code.svg";
import biologyIcon from "../assets/icons/biology.svg";
import physicsIcon from "../assets/icons/physicsIcon.svg";
import chemistryIcon from "../assets/icons/chemistryIcon.svg";
import javascriptIcon from "../assets/icons/javascript.svg";
import javaIcon from "../assets/icons/java.svg";
import pythonIcon from "../assets/icons/python.svg";
import summerizerIcon from "../assets/icons/LessonSummerizer.svg";

const Dashboard = () => {
  const {
    user,
    setUser
  } = useContext(UserContext);
  const { token } = JSON.parse(localStorage.getItem("user"));

  // fetch user profile and update usercontext
  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/miscs/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json(); 

      setUser(data)
     
    };

    fetchUser();
  }, []);

  return (
    
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 h-4/5 bg-slate-50 rounded-3xl">
      
      <h2 className=" text-2xl mb-6">Featured Tools</h2>



      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-4">

        <Card
          name="Essay Writer"
          description="Write your essay with confidence and let the words flow"
          icon={writerIcon}
          destination="/writer"
        />
        <Card
          name="Assignment Helper"
          description="Struggling with your assignment? We can help you"
          icon={helperIcon}
          destination="/assignment-helper"
        />
        <Card
          name="Revisions Helper"
          description="Need help with your revisions? We can help you"
          icon={revisionsIcon}
          destination="/revision"
        />

        <Card
          name="Lesson Summerizer"
          description="Need your lessons summerized? We can help you"
          icon={summerizerIcon}
          destination="/lesson-summerizer"
        />

        <Card
          name="Ask Me Anything"
          description="Ask our AI anything or any topic and have a deep discussion about it"
          icon={codingIcon}
          destination="/ask-me-anything"
        />


      </div>

      <h2 className="text-2xl my-6">Science Tools</h2>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
        <Card
          name="Physics"
          description="AI Helper for physics questions"
          icon={physicsIcon}
          destination="/science/physics"
        />
        <Card
          name="Biology"
          description="Questions about biology"
          icon={biologyIcon}
          destination="/science/biology"
        />
        <Card
          name="Chemistry"
          description="Help with Questions about chemistry"
          icon={chemistryIcon}
          destination="/science/chemistry"
        />
      </div>

      <h2 className="text-2xl my-6">Coding Tools</h2>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
        <Card
          name="JavaScript"
          description="AI Helper for JavaScript coding questions"
          icon={javascriptIcon}
          destination="/coding/javascript"
        />
        <Card
          name="Java"
          description="AI Helper for Java coding questions"
          icon={javaIcon}
          destination="/coding/java"
        />
        <Card
          name="Python"
          description="AI Helper for Python coding questions"
          icon={pythonIcon}
          destination="/coding/python"
        />
      </div>
    </div>
  );
};

export default Dashboard;
