import React from "react";
import { Link } from "react-router-dom";

const Card = ({ name, description, icon, destination }) => {
  return (
    <Link to={destination}>
      <div className="bg-white shadow-md rounded-lg p-4 hover:drop-shadow-xl ">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 ">
            <img className="h-12 w-12 " src={icon} alt="icon" />
          </div>
          <div>
            <div className="text-lg font-medium text-gray-900">{name}</div>
          </div>
        </div>
        <div className="mt-3">
          <p className="text-base text-gray-500">{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
