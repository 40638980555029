import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SaveConfirmationPopup = ({ isOpen, onSave, onClose, qna }) => {
  const [revisionName, setRevisionName] = useState("");

  const navigate = useNavigate();
  
  const handleSave = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem("user"));
      const { token } = userToken;
  
      const data = {[revisionName]: qna}; // create an object with revisionName as key and qna as its value
  
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/tools/revisions`,
        {
          qna: qna,
            revisionName: revisionName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("Data saved successfully");

      navigate("/revision", {
       
      });

    } catch (error) {
      console.error(error);
    }
  
    onClose();
  };
  

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${
        isOpen ? "flex" : "hidden"
      }`}
    >
      <div className="SaveConfirmationPopup bg-white rounded-lg shadow-md p-4">
        <h3 className="mb-4">Do you want to save this generated revision?</h3>
        <input
          type="text"
          placeholder="Enter a name for the revision"
          className="w-full p-2 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-purple-500"
          onChange={(e) => setRevisionName(e.target.value)}
        ></input>
        <div className="flex flex-row p-2 my-1 justify-center">
          <button
            onClick={handleSave}
            className="bg-indigo-700 hover:bg-indigo-500 text-white border-indigo-700 rounded-md px-4 py-2 mx-1"
          >
            Yes
          </button>
          <button
            onClick={onClose}
            className="bg-white text-indigo-700 hover:text-indigo-500 border-indigo-700 border rounded-md px-4 py-2 mx-1"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveConfirmationPopup;
