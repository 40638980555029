import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from "..";
import { useStateContext } from "../../contexts/ContextProvider";
import { UserContext } from "../../contexts/UserContext";
import { useLogout } from "../../hooks/useLogout.js";
// import avatar from "../../assets/avatar.png";
import avatar from "../../assets/profile.svg";
import { BsCurrencyDollar, BsShield } from "react-icons/bs";
import { FiCreditCard } from "react-icons/fi";

 const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'Billing Section',
    desc: 'Check your plan and billing details',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  // {
  //   icon: <BsShield />,
  //   title: 'My Inbox',
  //   desc: 'Messages & Emails',
  //   iconColor: 'rgb(0, 194, 146)',
  //   iconBg: 'rgb(235, 250, 242)',
  // },
  // {
  //   icon: <FiCreditCard />,
  //   title: 'My Tasks',
  //   desc: 'To-do and Daily Tasks',
  //   iconColor: 'rgb(255, 244, 229)',
  //   iconBg: 'rgb(254, 201, 15)',
  // },
];

const UserProfile = () => {
  const { currentColor, handleClick, handleClose } = useStateContext();
  const { token } = JSON.parse(localStorage.getItem("user"));
  const { user } = useContext(UserContext);
  const { logout } = useLogout();

 const  navigate = useNavigate();

  const handdleLogout = (e) => {
    e.preventDefault();
    logout();
  }; 

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {/* Abdirahman M. Abubakar */}
            {user.firstName} {user.lastName}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {/* Administrator */}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {user.email}
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
         <div
         key={index}
         className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer dark:hover:bg-[#42464D]"
         onClick={() => { // add onClick handler to navigate to Billing Section
           if (item.title === "Billing Section") {
            // <Link to="/settings/billing" />;
            navigate("/settings/billing");
            handleClose();
            
           }
         }}
       >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {item.desc}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-5">
        <form
          onClick={(e) => {
            handdleLogout(e);
          }}
        >
          <Button
            color="white"
            bgColor={currentColor}
            text="Logout"
            borderRadius="10px"
            width="full"
          />
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
