import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  Navigate,
} from "react-router-dom";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { useStateContext } from "./contexts/ContextProvider";
import { useAuthContext } from "./hooks/useAuthContext";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Assignment,
  AskMeAnything,
  Dashboard,
  Science,
  Writer,
  Coding,
  CodingHelper,
  Login,
  Signup,
  ScienceQuestionHelper,
  BillingSection,
  Revisions,
  RevisionsAnswer,
  LessonSummerizer,
} from "./pages";

function App() {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  const { user } = useAuthContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const userToken = localStorage.getItem("user");

  return (
    <div className="App">

{/* <p className=" text-center justify-center text-xl text-red-700">We apologize for the inconvenience, but we are currently experiencing some technical difficulties on our platform. Our team is working hard to resolve the issue as soon as possible.</p> */}
      
      <BrowserRouter>
        {/* check wether the user is logged on or not */}
        {userToken ? (
          <>
            <div className="flex relative dark:bg-main-dark-bg">
              {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
              <div
                className={
                  activeMenu
                    ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                    : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
                }
              >
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                  <Navbar />
                </div>

                <div>
                  {/* {themeSettings && <ThemeSettings />} */}

                  <Routes>
                    {/* dashboard  */}
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />

                    {/* Pages */}
                    <Route path="/tools" element={<Dashboard />} />
                    {/* <Route path="/tools" element={<Tools />} /> */}
                    <Route path="/writer" element={<Writer />} />
                    <Route path="/assignment-helper" element={<Assignment />} />
                    <Route path="/ask-me-anything" element={<AskMeAnything />} />
                    {/* Revisions Secttion */}
                    <Route path="/revision" element={<Revisions />} />
                    <Route path="/revision/answer" element={<RevisionsAnswer />} />
                    <Route path="lesson-summerizer" element={<LessonSummerizer />} />
                    {/* Science */}
                    <Route path="/science" element={<Science />} />
                    <Route
                      path="/science/chemistry"
                      element={<ScienceQuestionHelper subject="Chemistry" />}
                    />
                    <Route
                      path="/science/biology"
                      element={<ScienceQuestionHelper subject="Biology" />}
                    />
                    <Route
                      path="/science/physics"
                      element={<ScienceQuestionHelper subject="Physics" />}
                    />

                    {/* Coding */}
                    <Route path="/coding" element={<Coding />} />
                    <Route
                      path="/coding/java"
                      element={<CodingHelper language="Java" />}
                    />
                    <Route
                      path="/coding/python"
                      element={<CodingHelper language="Python" />}
                    />
                    <Route
                      path="/coding/javascript"
                      element={<CodingHelper language="JavaScript" />}
                    />
                    <Route
                      path="/coding/php"
                      element={<CodingHelper language="PHP" />}
                    />
                    <Route
                      path="/coding/sql"
                      element={<CodingHelper language="SQL" />}
                    />
                    <Route
                      path="/coding/html"
                      element={<CodingHelper language="HTML" />}
                    />
                    <Route
                      path="/coding/css"
                      element={<CodingHelper language="CSS" />}
                    />
                    <Route
                      path="/coding/react"
                      element={<CodingHelper language="React" />}
                    />

                    {/* Settings */}
                    <Route
                      path="/settings/billing"
                      element={<BillingSection />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
