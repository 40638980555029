import React from 'react'
import { MdDeleteForever } from 'react-icons/md'
import { GiOpenBook } from 'react-icons/gi'

const RevisionListCard = ({onDelete, onOpen, title, date}) => {
  return (
    <div className="flex flex-col mt-2 bg-gray-50 rounded-lg">
        <div className="flex justify-between items-center p-4">
          <div>
            <h3 className="text-lg text-gray-800 font-semibold">{title}</h3>
            <p className="text-sm text-gray-600">{date}</p>
          </div>
          <div className="flex space-x-2 ">
            <MdDeleteForever
              className="text-4xl text-gray-600 hover:text-gray-800 cursor-pointer"
              onClick={onDelete}
            />
            <GiOpenBook
              className="text-4xl text-gray-600 hover:text-gray-800 cursor-pointer"
              onClick={onOpen}
            />
          </div>
        </div>
      </div>
  )
}

export default RevisionListCard