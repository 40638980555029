import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import sendIcon from "../assets/icons/sendIcon.svg";
import chatBot from "../assets/icons/chatBot.svg";
import avatar from "../assets/icons/avatar.svg";
import { Header } from "../components";
import "./ChatStyling.css";
import { UserContext } from "../contexts/UserContext";

const generateUniqueId = () => {
  const timestamp = Date.now();
  const randomNumber = Math.random();
  const hexadecimalString = randomNumber.toString(16);

  return `id-${timestamp}-${hexadecimalString}`;
};

const chatStripe = (isAi, value, uniqueId) => {
  return (
    <div className={`wrapper ${isAi ? "ai" : ""}`}>
      <div className="chat">
        <div className="profile">
          <img src={isAi ? chatBot : avatar} alt={isAi ? "bot" : "user"} />
        </div>
        <div className="message" id={uniqueId}>
          {value}
        </div>
      </div>
    </div>
  );
};

const AskMeAnything = () => {
  const [messages, setMessages] = useState([]);

  const { user, setUser } = useContext(UserContext);
  const [credit, setCredit] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = document.getElementById("chat-input");
    const userMessage = input.value.trim();

    if (userMessage) {
      // Add user's message to messages state
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: userMessage, isAi: false },
      ]);

      // Clear input field
      input.value = "";

      // Add bot's message to messages state
      const uniqueId = generateUniqueId();
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: "", isAi: true, uniqueId },
      ]);

      // Send message to API with previous messages as context
      try {
        const usery = JSON.parse(localStorage.getItem("user"));
        const { token } = usery;
        const context = messages.map((m) => m.message).join("\n");

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/tools/askmeanything`,
          { message: userMessage + "\n\n Previous Conversation:" + context },

          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Update bot's message in messages state
        const botMessage = response.data;

        const newCredit = parseFloat(user.credit + 1); // add 1 to credit
        setUser({ ...user, credit: newCredit });

        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // remove the last empty message added for the bot
          { message: botMessage, isAi: true, uniqueId },
        ]);
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 402) {
          // alert("Not enough credit!");
          navigate("/settings/billing");
        }

        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // remove the last empty message added for the bot
          {
            message: "Oops! Something went wrong. Please try again later. It might that you have run out of credit. Please upgrade your account to continue using or contact us for more information.",
            isAi: true,
            uniqueId,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    const botMessage = messages.find((m) => m.isAi && !m.message);

    if (botMessage) {
      typeText(
        document.getElementById(botMessage.uniqueId),
        botMessage.message
      );
    }
  }, [messages]);

  const typeText = (element, text) => {
    let index = 0;

    let interval = setInterval(() => {
      if (element) {
        // add a null check here
        if (index < text.length) {
          element.innerHTML += text.charAt(index);
          index++;
        } else {
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 20);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10  bg-gray-50 rounded-3xl h-screen">
      <Header category="Tools" title="Ask Me Anything" />
      <div className="flex flex-col justify-end">
        <div className="overflow-y-auto flex-grow pb-20">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`chat-bubble ${
                message.isAi
                  ? "chat-bot justify-start"
                  : "chat-user justify-end"
              } flex mb-4`}
            >
              {message.isAi ? (
                <>
                  <img src={chatBot} className="w-3 h-3 mr-2" alt="Chat Bot" />
                  <p className="bg-gray-200 rounded-lg px-4 py-2">
                    {message.message}
                  </p>
                </>
              ) : (
                <>
                  <p className="bg-gray-300 rounded-lg px-4 py-2">
                    {message.message}
                  </p>
                  <img src={avatar} className="w-3 h-3 ml-2" alt="Avatar" />
                </>
              )}
            </div>
          ))}
        </div>

        <div className="container">
          <form
            className="form bg-gray-200 rounded-full p-2 flex items-center m-5 "
            onSubmit={handleSubmit}
          >
            <input
              id="chat-input"
              className="flex-1 bg-transparent  focus:outline-none pl-2"
              type="text"
              placeholder="Type a message..."
            />
            <button
              type="submit"
              className="flex-none"
              style={{ background: "none", border: "none" }}
            >
              <img
                src={sendIcon}
                alt="Send"
                style={{ width: "1.5rem", height: "1.5rem" }}
              />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AskMeAnything;
