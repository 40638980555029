import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    profile: "",
    credit: 0,
    email: "",
    plan: "",
    revisions: 0,
  });

  

  // update credit
  const updateUserCredit = (credit) => {
    setUser((prevUser) => ({ ...prevUser, credit }));
  };

  // update revisions
  const updateUserRevisions = (revisions) => {
    setUser((prevUser) => ({ ...prevUser, revisions }));
  };

  // update plan
  const updateUserPlan = (plan) => {
    setUser((prevUser) => ({ ...prevUser, plan }));
  };

  // update first name
  const updateFirstName = (firstName) => {
    setUser((prevUser) => ({ ...prevUser, firstName }));
  };

  // update last name
  const updateLastName = (lastName) => {
    setUser((prevUser) => ({ ...prevUser, lastName }));
  };

  // update email
  const updateEmail = (email) => {
    setUser((prevUser) => ({ ...prevUser, email }));
  };

  

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        updateUserCredit,
        updateUserPlan,
        updateFirstName,
        updateLastName,
        updateEmail,
        updateUserRevisions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
