import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../components";
import { AiOutlineDownload, AiOutlineSave } from "react-icons/ai";
import html2pdf from "html2pdf.js";
import { ClipLoader } from "react-spinners";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import RevisionCard from "../../components/revisions/RevisionCard";
import SaveConfirmationPopup from "../../components/revisions/SaveConfirmationPopup";

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;
const Spinner = styled(ClipLoader)`
  animation: ${spin} 1s linear infinite;
`;

const RevisionsAnswer = () => {
  const location = useLocation();
  const { chaptertopic, type } = location.state || {};
  const { revisionName, revisionData } = location.state || {};
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [qna, setQna] = useState([]);
  const userToken = JSON.parse(localStorage.getItem("user"));
  const { token } = userToken;
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [hideSave, setHideSave] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const handleDownload = () => {
    const options = {
      filename: "revisions.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      margin: 1,
      enableLinks: true,
    };

    // Map the responses to an array of HTML strings
    const responseHtml = qna.map(
      (item) => `
    <div>
      <h3>${item.question.split("Q: ")}</h3>
      <p>${item.answer}</p>
    </div>
  `
    );
    html2pdf().from(responseHtml.join("")).set(options).save();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/tools/revisionhelper`,
          { chapter: chaptertopic },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Split each pair into question and answer
        const qnaArray = result.data.response.map((item) => {
          const [question, answer] = item.split("A: ");
          return { question, answer };
        });

        const newCredit = parseFloat(user.credit + 1);
        setUser({ ...user, credit: newCredit });

        // update user revision
        const newRevision = parseFloat(user.revisions - 1);
        setUser({ ...user, revisions: newRevision });

        // if the request is unsuccessful with 400 status code

        setQna(qnaArray);
      } catch (error) {
       if (error.response){
          if (error.response.status === 400) {
            navigate("/revision");
          } else if (error.response.status === 402) {
            navigate("/settings/billing");
          }
       }
      } finally {
        setLoading(false);
      }
    };

    if (chaptertopic) {
      fetchData();
    } else if (revisionData) {
      setHideSave(true);
      setQna(revisionData);
    } else {
      navigate("/revision");
    }
  }, []);

  return (
    <div
      className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl"
      style={{ position: "relative", zIndex: 1 }}
    >
      <Header category="Tools" title="Revisions" />
      <div className="mt-10">
        {loading && (
          <div className="flex justify-center mt-8">
            <div className="flex flex-col justify-center items-center">
              <Spinner color="#4d05bd" loading={loading} size={50} />
              <h4>Generating Questions and Answers...</h4>
            </div>
          </div>
        )}

        {!loading && (
          <div>
            <div className="flex flex-row" id="generated-qnas">
              <h2 className="basis-1/2 text-2xl font-bold">Revision Topic</h2>
              <div className="flex flex-row basis-1/2 justify-end">
                <AiOutlineDownload
                  className=" text-2xl mx-2 cursor-pointer"
                  onClick={() => handleDownload()}
                />
                {!hideSave && (
                  <button onClick={() => setIsSaveConfirmationOpen(true)}>
                    <AiOutlineSave className=" text-2xl mx-2 cursor-pointer" />
                  </button>
                )}
              </div>
            </div>

            <div id="card-container">
              {qna.map((item, index) => (
                <RevisionCard
                  key={index}
                  index={index}
                  item={item}
                  dismissCard={() => {
                    const newQna = [...qna];
                    newQna.splice(index, 1);
                    setQna(newQna);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {isSaveConfirmationOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 0,
          }}
        />
      )}
      <SaveConfirmationPopup
        isOpen={isSaveConfirmationOpen}
        onSave={() => {
          console.log("Saving data...");
          setIsSaveConfirmationOpen(false);
        }}
        qna={qna}
        onClose={() => setIsSaveConfirmationOpen(false)}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10000,
        }}
      />
    </div>
  );
};

export default RevisionsAnswer;
