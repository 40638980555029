import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import {BsFileEarmarkText, BsCardChecklist} from 'react-icons/bs';


const CreditCounter = () => {
  const { user } = useContext(UserContext);
  const [credit, setCredit] = useState(0);
  const [revisions, setRevisions] = useState(0);

  const usertoken = JSON.parse(localStorage.getItem("user"));
  const { token } = usertoken;

  useEffect(() => {
    let isMounted = true;

    fetch(`${process.env.REACT_APP_BACKEND_URI}/miscs/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw new Error('Failed to fetch credit');
      })
      .then((data) => {
        if (isMounted) {
          setCredit(data.credit);
          setRevisions(data.revisions);
        }
        
        
      })
      .catch((error) => {
        console.log(error.message);
      });

    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    
      <div>
        <div className="flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray mx-2">
        <BsFileEarmarkText />
        <span className="capitalize">{credit} Words</span>
      </div>
      <div className="flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray mx-2">
        <BsCardChecklist />
        <span className="capitalize">{revisions} Revisions</span>
      </div>
      </div>
    
  );
};

export default CreditCounter;
