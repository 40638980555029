import React, { useState, useContext } from "react";
import { json, useNavigate } from "react-router-dom";
import axios from "axios"; 

import { Header } from "../components";
import { ToolsContextProvider } from "../contexts/ToolsContext";
import { useToolsContext } from "../hooks/useToolsContext";
import { UserContext } from "../contexts/UserContext";

const Writer = () => {
  const { dispatch } = useToolsContext();
  const navigate = useNavigate();

  const [essayTitle, setEssayTitle] = useState("");
  const [title, setTitle] = useState("");
  const [generatedEssay, setGeneratedEssay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  const { user, setUser } = useContext(UserContext);

  const userToken = JSON.parse(localStorage.getItem("user"));
  const { token } = userToken;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted");

  //  check if the user has enough credit
    if (user.credit < 10) {
      setError("Not enough credit.");
      navigate("/settings/billing");
      return;
    }


    setIsLoading(true); 

    const essay = { essayTitle, length: "500 Words" };

    try {
      
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/tools/essaywriter`,
        essay,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
        
      );

      setIsLoading(false); // set loading to false
      
      dispatch({ type: "ESSAY_WRITING", payload: response.data });
      setTitle(essayTitle);
      setEssayTitle("");
      setGeneratedEssay(response.data);

      const newCredit = parseFloat(user.credit + 1); 
      setUser({ ...user, credit: newCredit }); 

      setError(null);
      setEmptyFields([]);
    } catch (err) {
     
      if (err.response) {
        if (err.response.status === 402) {
          setIsLoading(false);
          setError("Not enough credit.");
          navigate("/settings/billing");
        } else {
          setError(err.response.data.error);
          setEmptyFields(err.response.data.emptyFields);
        }
     } else {
       setError("Something went wrong. Please try again later.");
     }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Tools" title="Essay Writer" />

      <div className="mt-10">
        <div id="essay-writer-input">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              required={true}
              className="border-2 py-2 px-4 rounded w-3/4 md:mr-10 sm:mr-10 mx-3  sm:w-4/5"
              placeholder="Essay Topic"
              onChange={(e) => setEssayTitle(e.target.value)}
              value={essayTitle}
            />
            <button className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded my-4 md:mx-0 sm w-3/2">
              Generate
            </button>
          </form>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center mt-10">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div
            id="essay-writer-output"
            className="rounded border-gray border border-spacing-1 p-5 mt-10"
          >
            <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
            <p className="text-gray-500">
              <span>{generatedEssay}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Writer;
