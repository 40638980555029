import React, { useState, useContext } from "react";
import { Header, TextInput } from "../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";
import { ClipLoader } from "react-spinners";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
to {
  transform: rotate(360deg);
}
`;
const Spinner = styled(ClipLoader)`
  animation: ${spin} 1s linear infinite;
`;

const LessonSummerizer = () => {
  const [text, setText] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [resultVisible, setResultVisible] = useState(false);

  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const userToken = JSON.parse(localStorage.getItem("user"));
  const { token } = userToken;

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    setText(text + pastedText);
  };

  const wordCount = text.trim().split(/\s+/).length;
  const remainingWords = 1501 - wordCount;
  const excessWords = remainingWords < 0 ? Math.abs(remainingWords) : 0;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFormVisible(false);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/tools/lessonsummerizer`,
        { chapter: text },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSummary(result.data.response);
      setText("");
      setLoading(false);

      const newCredit = parseFloat(user.credit + 1);
      setUser({ ...user, credit: newCredit });

      setResultVisible(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.log("error 400");
        } else if (error.response.status === 402) {
          navigate("/settings/billing");
        }
      }
      setLoading(false);
      setFormVisible(true);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Tools" title="Lesson Summerizer" />

      {formVisible && (
        <div className="flex flex-col">
          <form onSubmit={handleSubmit}>
            <textarea
              value={text}
              onChange={handleTextChange}
              onPaste={handlePaste}
              placeholder="Please enter your lesson here..."
              required={true}
              rows={20}
              className="w-full mt-4 p-2 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-purple-500"
            />
            <div
              className={`flex justify-between items-center mt-2 text-sm ${
                remainingWords < 0 ? "text-red-500" : ""
              }`}
            >
              <h2>
                {remainingWords > 0 ? remainingWords : 0} words remaining
                {excessWords > 0 ? `, exceeded by ${excessWords} words` : ""}
              </h2>
            </div>
            <button
              className={`bg-indigo-700 text-white rounded-md px-4 py-2 mt-4 ${
                remainingWords < 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={remainingWords < 0}
            >
              Get Summary
            </button>
          </form>
        </div>
      )}

      {loading && (
        <div className="flex justify-center mt-8">
          <div className="flex flex-col justify-center items-center">
            <Spinner color="#4d05bd" loading={loading} size={50} />
            <h4>Generating Summary Points....</h4>
          </div>
        </div>
      )}

      {resultVisible && (
        <div className="mt-6">
          <h3 className="text-lg font-medium mb-2">Summary:</h3>
          {summary.split(/\d+\./).map((point) => (
            <p key={point.trim()}>{point.trim()}</p> 
           
          ))}
        </div>
      )}
      
    </div>
  );
};

export default LessonSummerizer;
