import React from 'react'
import Header from '../components/Header'
import Cardv2 from '../components/Cardv2'

import codingIcon from "../assets/icons/code.svg";
import javaIcon from '../assets/icons/java.svg'
import pythonIcon from '../assets/icons/python.svg'
import javascriptIcon from '../assets/icons/javascript.svg'
import phpIcon from '../assets/icons/phpicon.svg'
import sqlIcon from '../assets/icons/sql.svg'
import htmlIcon from '../assets/icons/html.svg'
import cssIcon from '../assets/icons/css.svg'
import reactIcon from '../assets/icons/react.svg'


const Coding = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 h-4/5 bg-slate-50 rounded-3xl">
     <Header category="Tools" title="Coding Section" />

     <div className="grid  gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">

     <Cardv2 
          name="Java"
          description="AI Helper for Java coding questions"
          icon={javaIcon}
          destination="/coding/java"
        />

        <Cardv2
            name="Python"
            description="AI Helper for Python coding questions"
            icon={pythonIcon}
            destination="/coding/python"
        />

        <Cardv2
            name="JavaScript"
            description="AI Helper for JavaScript coding questions"
            icon={javascriptIcon}
            destination="/coding/javascript"
        />

        <Cardv2
            name="PHP"
            description="AI Helper for PHP coding questions"
            icon={phpIcon}
            destination="/coding/php"
        />

        <Cardv2
            name="SQL"
            description="AI Helper for SQL coding questions"
            icon={sqlIcon}
            destination="/coding/sql"
            
        />

        <Cardv2
            name="HTML"
            description="AI Helper for HTML coding questions"
            icon={htmlIcon}
            destination="/coding/html"
        />

        <Cardv2
            name="CSS"
            description="AI Helper for CSS coding questions"
            icon={cssIcon}
            destination="/coding/css"
        />

        <Cardv2
            name="React"
            description="AI Helper for React coding questions"
            icon={reactIcon}
            destination="/coding/react"
        />


     </div>

    </div>
  )
}

export default Coding