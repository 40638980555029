import React from "react";

import { Cardv2, Header } from "../components";
import biologyIcon from "../assets/icons/biology.svg";
import physicsIcon from "../assets/icons/physicsIcon.svg";
import chemistryIcon from "../assets/icons/chemistryIcon.svg";

const Science = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 h-4/5 bg-slate-50 rounded-3xl">
     <Header category="Tools" title="Science Section" />
      <div className="grid  gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
        <Cardv2 
          name="Physics"
          description="AI Helper for physics questions"
          icon={physicsIcon}
          destination="/science/physics"
        />
        <Cardv2 
          name="Biology"
          description="Questions about biology"
          icon={biologyIcon}
          destination="/science/biology"
        />
        <Cardv2 
          name="Chemistry"
          description="Help with Questions about chemistry"
          icon={chemistryIcon}
          destination="/science/chemistry"
        />
      </div>
    </div>
  );
};

export default Science;
