import { createContext, useReducer } from 'react'

export const ToolsContext = createContext()

export const toolsReducer = (state, action) => {
  switch (action.type) {
    case 'ESSAY_WRITING': 
      return {
        tools: action.payload
      }
    // case 'CREATE_WORKOUT':
    //   return {
    //     workouts: [action.payload, ...state.workouts]
    //   }
    // case 'DELETE_WORKOUT':
    //   return {
    //     workouts: state.workouts.filter((w) => w._id !== action.payload._id)
    //   }
    default:
      return state 
  }
}

export const ToolsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toolsReducer, {
    tools: null
  })

  return (
    <ToolsContext.Provider value={{...state, dispatch}}>
      { children }
    </ToolsContext.Provider>
  )
}