import React, { useState } from "react";
import { Header, RevisionsList } from "../../components";
import { useNavigate } from "react-router-dom";
import ChapterRevisionsInput from "../../components/revisions/ChapterRevisionsInput";

const Revisions = () => {
  const [text, setText] = useState("");
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const navigate = useNavigate();

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate("/revision/answer", {
      state: {
        chaptertopic: text,
      },
    });

    setText("");
  };

  const buttonData = [
    { title: "Topic" },
    { title: "Chapter" },
    { title: "My Revisions" }
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Tools" title="Revisions" />

      <div className="mt-4 flex flex-col">
        <div className="flex flex-row justify-center">
          {buttonData.map((button, index) => (
            <button
              key={index}
              className={`${
                activeButtonIndex === index
                  ? "bg-indigo-700 text-white"
                  : "bg-white text-indigo-700"
              } px-4 border border-indigo-700 py-2 mx-2 w-2/5 mt-4`}
              onClick={() => setActiveButtonIndex(index)}
            >
              {button.title}
            </button>
          ))}
        </div>

        {activeButtonIndex === 0 ? (
          <div className="flex flex-col mt-2">
            <form onSubmit={handleSubmit}>
              <input
                required={true}
                value={text}
                onChange={handleTextChange}
                type="text"
                placeholder="Enter your topic here..."
                className="w-full mt-4 p-2 border-2 border-gray-200 rounded-xl focus:outline-none focus:border-purple-500"
              />

              <button
                className={`bg-indigo-700 text-white rounded-md px-4 py-2 mt-4`}
              >
                Get Questions and Answers
              </button>
            </form>
          </div>
        ) : activeButtonIndex === 1 ? (
          <div className=" mt-4">
            <ChapterRevisionsInput/>
          </div>
        ) : activeButtonIndex === 2 ? (
          <div className=" mt-4">
            <RevisionsList />
          </div>
        )
        : null}
      </div>
    </div>
  );
};

export default Revisions;
